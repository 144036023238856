import {AircraftFragment, useAircraftCategoriesQuery, useInquireUpdateMutation,} from "gql/generted";
import {useState} from "react";
import {TripConfig, UseSearchConfig} from "types";
import AircractCategories from "./AircraftCategories";
import PersonalDetailsForm from "./PersonalDetailsForm";
import {useTranslation} from "react-i18next";
import ReactGA from "react-ga4";

type Props = Pick<
  UseSearchConfig,
  "passengers" | "trips" | "tripType" | "currency"
> & {
  trips: TripConfig[];
  onClickEditRequest(): void;
};

const QuoteResults = ({
                        onClickEditRequest,
                        passengers,
                        trips,
                        currency,
                        tripType,
                      }: Props) => {
  const [inquireId, setInquireId] = useState<null | number>(null);
  const [inquireMutation, {loading}] = useInquireUpdateMutation();
  const [, {language}] = useTranslation('common')
  const aircraftCategoriesQuery = useAircraftCategoriesQuery({
    variables: {
      currency: currency!,
      params: {
        passengers,
        trips: trips
          .map((trip) => {
            const routes = [
              {
                route: {
                  airportFrom:
                    trip.destinationValue.from?.id!,
                  airportTo: trip.destinationValue.to?.id!,
                },
                dateTimeValue: {
                  departDateTime: {
                    time: trip.dateTimeValue.departDateTime
                      .time,
                    date: trip.dateTimeValue.departDateTime.date?.toISODate(),
                  },
                },
              },
            ];

            if (
              trip.dateTimeValue.returnDateTime.date &&
              trips.length
            ) {
              routes.push({
                route: {
                  airportTo: trip.destinationValue.from?.id!,
                  airportFrom: trip.destinationValue.to?.id!,
                },
                dateTimeValue: {
                  departDateTime: {
                    time: trip.dateTimeValue.returnDateTime
                      .time,
                    date: trip.dateTimeValue.returnDateTime.date?.toISODate(),
                  },
                },
              });
            }

            return routes;
          })
          .flat(),
      },
    },
  });
  const aircraftCategories =
    aircraftCategoriesQuery.data?.aircraftCategories.aircrafts ?? [];

  const onSubmitPersonalDetailsForm = (newDealId: number) => {
    setInquireId(newDealId);
  };

  const onClickInquire = async (aircraft: AircraftFragment) => {
    if (loading) return;
    ReactGA.event({
      category: "VIEW RESULTS",
      action: "VIEW RESULTS",
    });
    await inquireMutation({
      variables: {
        inquireInput: {
          inquireId: inquireId!,
          aircraftId: aircraft.id,
        },
      },
    })
      .then(({data}) => {
        if (data?.inquireUpdate.ok) {
          ReactGA.event({
            category: "VIEW RESULTS",
            label: "VIEW RESULTS",
            value: (data?.inquireUpdate?.inquireCrmId ?? '') as number,
            action: "VIEW RESULTS",
          });

          // TODO: SAVE USER SELECTION
          const thankYouUrl = language === 'es'
            ? "https://israjets.com/gracias/?lang=es"
            : "https://israjets.com/thank-you/";
          window.location.assign(thankYouUrl);
        } else {
          console.error("Error on update inquire");
        }
      })
      .catch(console.error);
  };

  return inquireId === null ? (
    <PersonalDetailsForm
      onSubmit={onSubmitPersonalDetailsForm}
      onClickHome={onClickEditRequest}
      passengers={passengers}
      trips={trips}
      tripType={tripType}
    />
  ) : (
    <AircractCategories
      tripType={tripType}
      passengers={passengers}
      trips={trips}
      currency={currency}
      aircraftCategories={aircraftCategories}
      onClickEditRequest={onClickEditRequest}
      onClickInquire={onClickInquire}
    />
  );
};

export default QuoteResults;
