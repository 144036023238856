import update from "immutability-helper";
import {DateTime} from "luxon";
import {DateTimeValue, TripConfig} from "types";
import DateTimeSelector from "./DateTimeSelector";
import styled from "styled-components";
import {device} from "constant/styles";
import {useTranslation} from "react-i18next";
import {ForwardedRef, forwardRef, useImperativeHandle, useRef} from "react";

type Props = {
  readOnly?: true;
  departOnly?: boolean;
  minDateForSelect?: DateTime | undefined;
  maxDateForSelect?: DateTime | undefined;
  className?: string;
  departFieldClassName?: string;
  returnFieldClassName?: string;
  value: TripConfig["dateTimeValue"];
  onChange?: (v: TripConfig["dateTimeValue"]) => void;
};

const SelectorGroup = styled.div`
    display: flex;

    @media ${device.phone} {
        flex-direction: column;
    }

    & > * {
        flex-grow: 2;
        flex-shrink: 0;
        flex-basis: 0;
    }
`;

const DateTimePicker = forwardRef(
  ({
     readOnly,
     departOnly,
     minDateForSelect,
     maxDateForSelect,
     className,
     departFieldClassName,
     returnFieldClassName,
     value,
     onChange,
   }: Props, ref: ForwardedRef<any>) => {
    const [t] = useTranslation('common')
    const departRef = useRef<any>(null);
    const returnRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      openDepartDropdown() {
        departRef.current?.openCalendarDropdown();
      },
      openReturnDropdown() {
        returnRef.current?.openCalendarDropdown();
      }
    }));

    const onChangeDepartDateTime = (newValue: DateTimeValue) => {
      if (
        value.returnDateTime.date &&
        newValue.date! > value.returnDateTime.date
      ) {
        onChange?.(
          update(value, {
            returnDateTime: {
              date: () => newValue.date,
            },
            departDateTime: {
              date: (current) => current || value.returnDateTime.date,
            },
          }),
        );
      } else {
        onChange?.(
          update(value, {
            departDateTime: () => newValue,
          })
        );
      }
    };

    const onChangeReturnDateTime = (newValue: DateTimeValue) => {
      if (
        value.departDateTime.date &&
        newValue.date! < value.departDateTime.date
      ) {
        onChange?.(
          update(value, {
            departDateTime: {
              date: () => newValue.date,
            },
            returnDateTime: {
              date: (current) => current || value.departDateTime.date,
            },
          }),
        );
      } else {
        onChange?.(
          update(value, {
            returnDateTime: () => newValue,
          }),
        );
      }
    };

    return (
      <div className={className}>
        <SelectorGroup>
          <DateTimeSelector
            focusNext={() => returnRef.current?.openCalendarDropdown()}
            ref={departRef}
            readOnly={readOnly}
            className={departFieldClassName}
            value={value.departDateTime}
            onChange={onChangeDepartDateTime}
            label={t('depart')}
            calendarLabel={t('selectDeparture')}
            minDateForSelect={minDateForSelect}
            maxDateForSelect={maxDateForSelect}
            rangeDate={!departOnly}
            startDate={value.departDateTime.date}
            endDate={value.returnDateTime.date}
          />
          {!departOnly && (
            <DateTimeSelector
              ref={returnRef}
              readOnly={readOnly}
              className={returnFieldClassName}
              value={value.returnDateTime}
              onChange={onChangeReturnDateTime}
              label={t('return')}
              calendarLabel={t('selectReturn')}
              minDateForSelect={minDateForSelect}
              maxDateForSelect={maxDateForSelect}
              rangeDate={true}
              startDate={value.departDateTime.date}
              endDate={value.returnDateTime.date}
            />
          )}
        </SelectorGroup>
      </div>
    );
  });

export default DateTimePicker;
