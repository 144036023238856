import {DestinationValue} from "types";
import AirportField from "./AirportField";
import {IconReverse} from "components/Icons";
import update from "immutability-helper";
import {MouseEventHandler, useRef} from "react";
import styled from "styled-components";
import {device} from "constant/styles";
import {useTranslation} from "react-i18next";


type Props = {
  readOnly?: true;
  className?: string;
  aiportFieldToClassName?: string;
  aiportFieldFromClassName?: string;
  value: DestinationValue;
  onChange?(v: DestinationValue): void;
  openCalendarDropdown?(): void;
};

const Component = styled.div`
    position: relative;
    display: flex;

    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
    }

    @media ${device.phone} {
        flex-direction: column;
    }
`;

const BtnReverse = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%) translateY(-50%);

    svg {
        width: 28px;
        height: 28px;
    }

    @media ${device.phone} {
        left: unset;
        right: 0;
        transform: translateX(50%) translateY(-50%);
    }
`;

const DestinationFields = ({
                             className,
                             value,
                             readOnly,
                             onChange,
                             aiportFieldToClassName,
                             aiportFieldFromClassName,
                             openCalendarDropdown
                           }: Props) => {
  const [t] = useTranslation('common')
  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);
  const onFocusTo = () => toRef?.current?.focus()
  const onClickBtnReverse: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onChange?.(
      update(value, {
        to: () => value.from,
        from: () => value.to,
      })
    );
  };

  const onChangeFrom = (newValueFrom: DestinationValue["from"]) => {
    onChange?.(
      update(value, {
        from: () => newValueFrom,
      })
    );
    newValueFrom && onFocusTo()
  };

  const onChangeTo = (newValueTo: DestinationValue["to"]) => {
    onChange?.(
      update(value, {
        to: () => newValueTo,
      }),
    );
    newValueTo && openCalendarDropdown?.()
  };

  return (
    <Component className={className}>
      <AirportField
        ref={fromRef}
        readOnly={readOnly}
        label={t('from')}
        value={value.from}
        onChange={onChangeFrom}
        className={aiportFieldFromClassName}
      />
      <AirportField
        ref={toRef}
        readOnly={readOnly}
        label={t('to')}
        value={value.to}
        onChange={onChangeTo}
        className={aiportFieldToClassName}
      />

      {!readOnly && (
        <BtnReverse type="button" onClick={onClickBtnReverse}>
          <IconReverse/>
        </BtnReverse>
      )}
    </Component>
  );
};

export default DestinationFields;
