import {default as classNames, default as classnames} from "classnames";
import {IconAngleLeft, IconCalendar} from "components/Icons";
import {BREAKPOINT_DESKTOP, BREAKPOINT_IPAD} from "constant";
import {useDropdown, useIsTouchableDevice, useWidthCondition} from "helpers";
import update from "immutability-helper";
import {DateTime} from "luxon";
import {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {DateTimeValue} from "types";
import Calendar from "../Calendar";
import styled from "styled-components";
import {colors, device, typography} from "constant/styles";
import {DropdownContent, ScrollbarContainer} from "components/UI";
import {useTranslation} from "react-i18next";

type Props = {
  value: DateTimeValue;
  readOnly?: true;
  onChange: (v: DateTimeValue) => void;
  className?: string;
  label: string;
  calendarLabel: string;
  minDateForSelect?: DateTime | undefined;
  maxDateForSelect?: DateTime | undefined;
  rangeDate: boolean;
  withTimePicker?: boolean;
  startDate?: DateTime | undefined;
  endDate?: DateTime | undefined;
  focusNext?: () => void;
};

const Component = styled.div`
    border: 1px solid transparent;
    background: ${colors.white.primary};
    height: 60px;
    border-left-color: ${colors.grey.secondary};
    transition: 0.2s border-color;
    position: relative;

    @media ${device.phone} {
        border-left-color: transparent;
        border-bottom-color: ${colors.grey.secondary};
    }

    &.focused {
        border-color: ${colors.black.primary};
    }
`;

const Controller = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
`;

const ControllerDate = styled.div`
    position: relative;
    flex-grow: 1;
`;
const ControllerTime = styled.div`
    position: relative;
    flex-grow: unset;
    flex-basis: 90px;
    flex-shrink: 0;

    @media ${device.phone} {
        flex-basis: 50%;
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        height: 18px;
        transform: translateY(-50%);
        background: ${colors.grey.primary};
        width: 1px;
    }
`;

const ControllerLabel = styled.span`
    position: absolute;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    color: ${colors.white.primary};
    top: 50%;
    transform: translateY(-80%);
    transition: 0.2s;
    top: -8px;
    opacity: 0;

    &.hasValue {
        transform: translateY(-100%);
        opacity: 1;

        @media ${device.phone} {
            opacity: 0;
        }
    }
`;

const ControllerDateLabel = styled(ControllerLabel)`
    left: 16px;
`;

const ControllerTimeLabel = styled(ControllerLabel)`
    left: 10px;
`;

const ControllerIcon = styled(IconCalendar)`
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
`;

const ControllerInput = styled.input`
    position: relative;
    z-index: 1;
    display: inline-block;
    border: none;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: ${typography.fontFamily};
    cursor: pointer;
    color: ${colors.black.primary};

    &::placeholder {
        color: ${colors.grey.dark};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        font-family: ${typography.fontFamily};
    }

    &.readOnly {
        cursor: default;
    }
`;

const ControllerDateInput = styled(ControllerInput)`
    padding: 18px 10px 18px 44px;
`;

const ControllerTimeInput = styled(ControllerInput)`
    padding: 18px 10px;
`;

const Content = styled(DropdownContent)`
    padding: 3px;
    min-width: 200px;

    @media ${device.phone} {
        width: 100%;
    }
`;

const ContentWrapper = styled(ScrollbarContainer)`
    max-height: 184px;
    overflow-x: hidden;
    padding-right: 2px;
`;

const ContentItem = styled.li`
    display: block;
    width: 100%;

    button {
        display: block;
        width: 100%;
        border-radius: 0;
        padding: 11px 2px 11px 12px;
        background-color: transparent;
        color: ${colors.black.primary};
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        font-family: ${typography.fontFamily};

        &:hover {
            background-color: #29247a;
            color: ${colors.white.primary};
        }

        &.selected {
            background-color: #29247a;
            color: ${colors.white.primary};
        }
    }
`;

const CalendarContent = styled(DropdownContent)`
    width: 312px;

    @media ${device.phone} {
        width: 294px;
    }
`;

const CalendarContentWrapper = styled.div`
    padding: 20px 16px;

    @media ${device.phone} {
        padding: 8px 4px;
    }
`;

const CalendarContentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${colors.grey.secondary};
`;

const CalendarContentTitle = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    font-family: ${typography.fontFamily};
    color: ${colors.black.primary};
    margin: 0;
`;

const CalendarContentGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    position: relative;
`;

const CalendarPaginationBtn = styled.button`
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        opacity: 0;
        cursor: default;
    }

    &.prev {
        left: 0;
    }

    &.next {
        right: 0;
        transform: rotate(-180deg);
    }
`;

const generateTimeOptions = (
  startFromHour: number,
  startFromMinutes: number,
  stepInMinutes: number
) => {
  const options = [{hour: startFromHour, minute: startFromMinutes}];
  while (true) {
    const newOption = {...options[options.length - 1]};
    newOption.minute += stepInMinutes;
    if (newOption.minute >= 60) {
      newOption.minute = 0;
      newOption.hour += 1;
    }
    options.push(newOption);

    if (newOption.hour === 23 && newOption.minute + stepInMinutes >= 60) {
      break;
    }
  }

  return options;
};

const formatTime = (value: DateTimeValue["time"]) => {
  if (!value) return "";
  const isPM = value.hour >= 12;
  return `${
    value.hour % 12 === 0
      ? 12
      : (value.hour - (isPM ? 12 : 0)).toString().padStart(2, "0")
  }:${value.minute.toString().padStart(2, "0")} ${isPM ? "PM" : "AM"}`;
};

const resetTime = {
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
};

const DateTimeSelector = forwardRef(
  ({
     value,
     readOnly,
     onChange,
     className,
     label,
     calendarLabel,
     minDateForSelect,
     maxDateForSelect,
     rangeDate,
     withTimePicker = true,
     startDate,
     focusNext,
     endDate,
   }: Props, ref: ForwardedRef<any>) => {
    const focusableTimeOptionRef = useRef<HTMLLIElement>(null);
    const isTouchableDevice = useIsTouchableDevice();
    const isIpad = useWidthCondition((w) => w < BREAKPOINT_DESKTOP);
    const isPhone = useWidthCondition((w) => w < BREAKPOINT_IPAD);
    const [t] = useTranslation('common')
    const [hoveredCalendarDate, setHoveredCalendarDate] = useState<DateTime>();
    const [calendar, setCalendar] = useState(
      DateTime.local().set({...resetTime, day: 1})
    );

    const {minDate, maxDate} = useMemo(() => {
      let maxDate =
        maxDateForSelect ||
        DateTime.local()
          .set({
            ...resetTime,
          })
          .plus({year: 1});

      maxDate = maxDate.set({day: maxDate.daysInMonth});

      return {
        minDate: minDateForSelect || DateTime.local().set(resetTime),
        maxDate,
      };
    }, [minDateForSelect, maxDateForSelect]);

    const dropdownTime = useDropdown({
      popperOffsetY: isPhone ? 0 : isIpad ? -15 : undefined,
      popperOptions: {
        placement: isPhone ? "bottom" : "bottom-start",
      },
    });

    const dropdownCalendar = useDropdown({
      popperOffsetY: isPhone ? 0 : isIpad ? -15 : undefined,
      popperOptions: {
        placement: isPhone ? "bottom" : "bottom-start",
      },
    });

    const timeLabel = useMemo(() => t('time'), [t]);

    const disabledPrevMonthBtn =
      calendar.minus({month: 1}) < minDate.set({day: 1});
    const disabledNextMonthBtn = calendar.plus({month: 1}) > maxDate;

    const timeOptions = useMemo(() => {
      return generateTimeOptions(0, 0, 60);
    }, []);

    const onSelectTimeOption = (option: DateTimeValue["time"]) => {
      dropdownTime.toggle();
      onChange(
        update(value, {
          time: () => option,
        })
      );
      focusNext?.()
    };

    const onClickTimeField = () => {
      if (readOnly) return;
      dropdownCalendar.setOpen(false);
      dropdownTime.toggle();
    };

    const onClickDateField = () => {
      if (readOnly) return;

      dropdownTime.setOpen(false);
      dropdownCalendar.toggle();
    };

    const onClickPrevMonth = () => {
      setCalendar(calendar.minus({month: 1}));
    };

    const onClickNextMonth = () => {
      setCalendar(calendar.plus({month: 1}));
    };

    const upTimeLabel = value.time || dropdownTime.open;
    const upDateLabel = value.date || dropdownCalendar.open;

    useEffect(() => {
      if (dropdownTime.open && focusableTimeOptionRef.current) {
        focusableTimeOptionRef.current.parentElement?.scrollTo({
          left: 0,
          top: focusableTimeOptionRef.current.offsetTop,
        });
      }
    }, [dropdownTime.open]);

    useImperativeHandle(ref, () => ({
      openCalendarDropdown() {
        dropdownCalendar.setOpen(true);
      },
      closeCalendarDropdown() {
        dropdownCalendar.setOpen(false);
      },
      openTimeDropdown() {
        dropdownTime.setOpen(true);
      },
      closeTimeDropdown() {
        dropdownTime.setOpen(false);
      }
    }));


    return (
      <Component
        className={classnames(
          (dropdownTime.open || dropdownCalendar.open) && "focused",
          className
        )}
        ref={(ref) => {
          if (isPhone) {
            dropdownCalendar.setRef(ref);
            dropdownTime.setRef(ref);
          }
        }}
      >
        <Controller>
          <ControllerDate
            ref={!isPhone ? dropdownCalendar.setRef : undefined}
          >
            {!readOnly && (
              <ControllerDateLabel
                className={classnames(upDateLabel && "hasValue")}
              >
                {label}
              </ControllerDateLabel>
            )}
            <ControllerDateInput
              readOnly
              placeholder={upDateLabel ? "" : label}
              onClick={onClickDateField}
              value={
                value.date?.toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                ) ?? ""
              }
              className={classnames(
                value.date && "hasValue",
                readOnly && "readOnly"
              )}
            />
            <ControllerIcon/>
          </ControllerDate>
          {withTimePicker && (
            <ControllerTime
              ref={!isPhone ? dropdownTime.setRef : undefined}
            >
              {!readOnly && (
                <ControllerTimeLabel>
                  {t(timeLabel)}
                </ControllerTimeLabel>
              )}
              <ControllerTimeInput
                readOnly
                placeholder={upTimeLabel ? "" : timeLabel}
                value={formatTime(value.time)}
                onClick={onClickTimeField}
                className={classnames(
                  value.time && "hasValue",
                  readOnly && "readOnly"
                )}
              />
            </ControllerTime>
          )}
        </Controller>

        <Content
          ref={dropdownTime.setPopperRef}
          style={dropdownTime.popper.styles.popper}
          {...dropdownTime.popper.attributes.popper}
          data-open={dropdownTime.open || undefined}
        >
          <ContentWrapper>
            {timeOptions.map((option) => {
              const label = formatTime(option);
              const selected =
                value.time?.hour === option.hour &&
                value.time.minute === option.minute;
              return (
                <ContentItem
                  ref={
                    option.hour === 9 || selected
                      ? focusableTimeOptionRef
                      : undefined
                  }
                  key={label}
                >
                  <button
                    type="button"
                    className={classnames({
                      selected: selected,
                    })}
                    onClick={() => onSelectTimeOption(option)}
                  >
                    {label}
                  </button>
                </ContentItem>
              );
            })}
          </ContentWrapper>
        </Content>

        <CalendarContent
          ref={dropdownCalendar.setPopperRef}
          style={dropdownCalendar.popper.styles.popper}
          {...dropdownCalendar.popper.attributes.popper}
          data-open={dropdownCalendar.open || undefined}
        >
          <CalendarContentWrapper>
            <CalendarContentHeader>
              <CalendarContentTitle>
                {calendarLabel}
              </CalendarContentTitle>
            </CalendarContentHeader>

            <CalendarContentGroup>
              <CalendarPaginationBtn
                onClick={onClickPrevMonth}
                disabled={disabledPrevMonthBtn}
                className={classNames("prev")}
                type="button"
              >
                <IconAngleLeft/>
              </CalendarPaginationBtn>
              <CalendarPaginationBtn
                onClick={onClickNextMonth}
                disabled={disabledNextMonthBtn}
                className={classNames("next")}
                type="button"
              >
                <IconAngleLeft/>
              </CalendarPaginationBtn>

              <Calendar
                onHoverDate={
                  rangeDate && isTouchableDevice
                    ? undefined
                    : setHoveredCalendarDate
                }
                hoveredDate={
                  rangeDate && isTouchableDevice
                    ? undefined
                    : hoveredCalendarDate
                }
                month={calendar.month}
                year={calendar.year}
                onClickDate={(date) => {
                  onChange({...value, date});
                  dropdownCalendar.setOpen(false);
                  withTimePicker ? dropdownTime.setOpen(true) : focusNext?.();
                }}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </CalendarContentGroup>
          </CalendarContentWrapper>
        </CalendarContent>
      </Component>
    );
  });

export default DateTimeSelector;
